//PAGE
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PageContent from '../layouts/PageContent';
//API
import axios, { AxiosError } from 'axios';
import { oktaClients } from '../../util/Okta';
import settings from '../../settings.json';
//COMPONENTS
import { Button, Divider, message, Popover, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import {
    CloudUploadOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    FileImageOutlined,
    FileSearchOutlined,
    LikeOutlined,
    MonitorOutlined,
    ReloadOutlined,
    SendOutlined,
} from '@ant-design/icons';
//SEARCH PARAM
import { useSearchParams } from 'react-router-dom';

//get api prefix
const settingEnv = (settings as any)[window.location.host] || settings.default;
const apiPrefix = settingEnv.api_prefixes.file_upload;
const { useContinuousAuth } = oktaClients.file_upload;

export function FileUploadDetails () {
    return (
        <PageContent
            authenticated={true}
            bodyColor='#fff'
            expanded={true}
            clientKey='file_upload'
            clientName='File Upload'
        >
            <AuthedFileUploadDetails />
        </PageContent>
    );
}

//styled components
const FileUploadBody = styled.div`
    margin: 0px 0px 40px 0px;
    padding: 10px;
    max-width: 770px;

    .ant-table-column-sort {
        background: #fff !important; //override default ANTD Table Column Sort background color
    }
`;
const RequestStatusCompleted = styled.div`
    align-items: center;
    border: 1px solid #40A142;
    border-radius: 20px;
    color: #40A142;
    display: flex;
    font-size: 13px;
    font-family: equipmedium;
    justify-content: center;
    margin: 5px 9px;
    padding: 5px 10px;
    width: fit-content;
`;
const RequestStatusFailed = styled.div`
    align-items: center;
    border: 1px solid red;
    border-radius: 20px;
    color: red;
    display: flex;
    font-size: 13px;
    font-family: equipmedium;
    justify-content: center;
    margin: 5px 9px;
    padding: 5px 10px;
    width: fit-content;
`;
const RequestStatusScanning = styled.div`
    align-items: center;
    border: 1px solid #1998d5;
    border-radius: 20px;
    color: #1998d5;
    display: flex;
    font-size: 13px;
    font-family: equipmedium;
    justify-content: center;
    margin: 5px 9px;
    padding: 5px 10px;
    width: fit-content;
`;
const RequestStatusUploading = styled.div`
    align-items: center;
    border: 1px solid #1998d5;
    border-radius: 20px;
    color: #1998d5;
    display: flex;
    font-size: 13px;
    font-family: equipmedium;
    justify-content: center;
    margin: 5px 9px;
    padding: 5px 10px;
    width: fit-content;
`;
const RequestStatusRequested = styled.div`
    align-items: center;
    border: 1px solid orange;
    border-radius: 20px;
    color: orange;
    display: flex;
    font-size: 13px;
    font-family: equipmedium;
    justify-content: center;
    margin: 5px 9px;
    padding: 5px 10px;
    width: fit-content;
`;
const FileDetailsReminder = styled.p`
    font-size: 10px;
    margin: 5px 0px;
`;

const TableRowDate = styled.p`
    margin: -10px 0px;
    font-size: 12px;
`;

const FileWrapper = styled.div`
`;
const ButtonWrapper = styled.div`
`;
const FileDetailsPopup = styled.div`
`;
const FileDetailsHeader = styled.div`
    color: #1890ff;
`;
const FileNameText = styled.span`
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    whiteSpace: nowrap;
    width: 100%;
    max-width: 470px;

    // ensure file name fills available space when the user is not in a condensed view
    @media (min-width: 900px) {
        min-width: 470px;
    }
`;
const UploadDetailsValue = styled.div`
    font-size: 12px;
    font-family: 'equipmedium';
    margin: 0px auto 10px auto;
    width: 100%;
`;
const UploadDetailsLabel = styled.div`
    font-size: 12px;
    margin: auto;
    width: 100%;
`;

const OpenAttachmentText = styled.span`
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    whiteSpace: nowrap;
    width: 100%;
`;

type ListUploadsRequest = {
    caseId?: string;
    customerId?: string;
};

type FileEntry = {
    Name: string;
    FileId?: string;
};

type ListUploadEntry = {
    caseId: string;
    uploadId: string;
    createdAt: string;
    expiresAt: string;
    status: string;
    fileName?: string; // legacy support
    files?: FileEntry[];
    conversionStatus?: string;
};

type FlattenedListUploadEntry = {
    caseId: string;
    uploadId: string;
    createdAt: string;
    expiresAt: string;
    status: string;
    fileId?: string;
    fileName?: string;
    conversionStatus?: string;
};

type ListUploadsResponse = ListUploadEntry[];

type CreateUploadRequest = {
    uploadId?: string; // resend a request
    caseId?: string;
};

type CreateUploadResponse = {
    uploadId: string;
};

type DownloadAttachmentButtonProps = {
    caseId: string;
    conversionStatus: string;
    uploadId: string;
    fileName: string; // legacy support
    fileId?: string; // multi-file support
    multiFile: boolean;
};

function DownloadAttachmentButton (props: DownloadAttachmentButtonProps) {
    const {
        caseId,
        conversionStatus,
        uploadId,
        fileName,
        fileId,
        multiFile,
    } = props;

    const downloadFile = useCallback(async () => {
        const fileViewerUrl = new URL('/file-viewer', document.location.href);
        fileViewerUrl.searchParams.set('caseId', caseId);
        fileViewerUrl.searchParams.set('uploadId', uploadId);
        // multi file support
        if (fileId) {
            fileViewerUrl.searchParams.set('fileId', fileId);
        }
        open(fileViewerUrl, '_blank');
    }, [caseId, uploadId, fileId]);

    // Converted File Support - Determine if the download button should be disabled
    const isDownloadDisabled = conversionStatus !== 'Completed';

    // Converted File Support - Customize Download Popup details based on File Conversion Status
    let conversionStatuses: Record<string, string> = {
        'N/A': 'File Conversion Status is Unavailable',
        'Completed': fileName,
        'Pending': 'File Conversion in Progress...',
        'FailedContentType': 'Failed to Convert File - Incompatible Content Type',
        'FailedMaxPages': 'Failed to Convert File - Max Page Limit Exceeded',
        'FailedPdfConversion': 'Failed to Convert PDF File',
        'FailedJpgConversion': 'Failed to Convert JPG File',
        'FailedPageCount': 'Failed to Convert File - Page Count Exceeded',
        'FailedDownloadFile': 'Failed to Download File',
        'FailedSaveFile': 'Failed to Save File',
        'Failed': 'Failed to Convert File',
    };

    // Change Converted Button Styling based on ConversionStatus
    const getConvertedButtonStyle = () => {
        const lowerCaseStatus = (conversionStatus as string).toLowerCase();
        if (conversionStatus === 'Completed') {
            return { border: '1px solid #40A142', color: '#40A142' };
        }
        if (conversionStatus === 'Pending') {
            return { border: '1px solid #ccc', color: '#ccc' };
        }
        if (lowerCaseStatus.includes('failed')) {
            return { border: '1px solid red', color: 'red' };
        }
        // Default style if none of the above conditions are met
        return { border: '1px solid #ccc', color: '#ccc' };
    };

    const convertedButtonStyle = getConvertedButtonStyle();

    // Button On-Hover Popup
    const convertedFileDetailsPopup = (
        <FileDetailsPopup>
            <UploadDetailsLabel>
                File Name (Converted):
            </UploadDetailsLabel>
            <UploadDetailsValue>
                {conversionStatuses[conversionStatus]}
            </UploadDetailsValue>
        </FileDetailsPopup>
    );
    const fileDetailsHeader = (
        <FileDetailsHeader>
            <FileImageOutlined style={{ marginRight: '5px' }} />
            Details
        </FileDetailsHeader>
    );

    return (
        <ButtonWrapper>
            {/* Converted File Download Button */}
            <Popover content={convertedFileDetailsPopup} title={fileDetailsHeader}>
                <Button
                    type='default'
                    disabled={isDownloadDisabled}
                    onClick={downloadFile}
                    icon={<DownloadOutlined style={{ verticalAlign: '0.125em' }} />}
                    style={{
                        ...convertedButtonStyle,
                        display: 'block',
                        margin: multiFile ? '5px 0px' : '-5px 0px',
                        textAlign: 'left',
                        width: '120px',
                    }}
                >
                    <OpenAttachmentText>
                        Download
                    </OpenAttachmentText>
                </Button>
            </Popover>
        </ButtonWrapper>
    );
}

// Flatten the fileDetails data so each file associated with a request displays in it's own Table Row
function flattenFileDetails (fileDetails: ListUploadsResponse): FlattenedListUploadEntry[] {
    const flattenedData: any[] = [];

    fileDetails.forEach((uploadEntry) => {
        const { files, ...rest } = uploadEntry;
        if (files && files.length > 0) {
            files.forEach((file) => {
                flattenedData.push({
                    ...rest,
                    fileName: file.Name,
                    fileId: file.FileId,
                });
            });
        } else {
            // For legacy support where files array may not exist
            flattenedData.push({
                ...rest,
                fileName: uploadEntry.fileName || '',
                fileId: undefined,
            });
        }
    });

    return flattenedData;
}

// File Upload Details Page Content
function AuthedFileUploadDetails () {
    // url params
    const [searchParams] = useSearchParams();
    const caseId = searchParams.get('caseId') || undefined;
    const caseStatus = searchParams.get('status');
    const customerId = searchParams.get('customerId') || undefined;
    // file details
    const [flattenedFileDetails, setFlattenedFileDetails] = useState<FlattenedListUploadEntry[]>([]);
    const [requestStatus, setRequestStatus] = useState('');
    // loading
    const [createLoading, setCreateLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    // upload details
    const [uploadId, setUploadId] = useState('');

    const { getHeaders } = useContinuousAuth();

    useEffect(() => {
        if (customerId) {
            listFileDetails({ caseId, customerId });
        } else if (caseId) {
            listFileDetails({ caseId });
        }
    }, [caseId, customerId]);

    const createUpload = async (
        caseId: string,
        uploadId?: string,
    ) => {
        setLoading(true);
        const headers = await getHeaders();
        let payload: CreateUploadRequest = {};
        //set uploadId if it exists
        if (uploadId) {
            payload.uploadId = uploadId;
        }
        payload.caseId = caseId;

        try {
            const { data } = await axios.post<CreateUploadResponse>(`${apiPrefix}/api/upload`, payload, { headers });
            setUploadId(data.uploadId);
            await listFileDetails({ uploadId: data.uploadId, caseId });
        } catch (e: unknown) {
            if (e instanceof AxiosError && e.response) {
                const error = e.response.data.error;
                if (error.includes('Unable to send file upload link') || error.includes('Error calling webhook')) {
                    message.error('There was a problem creating your Request, email cannot be sent');
                } else if (error.includes('Case status is closed') || error.includes('status')) {
                    message.error('There was a problem creating your Request, status is invalid');
                } else if (error.includes('Invalid case ID') || error.includes('Error getting case')) {
                    message.error('There was a problem creating your Request, case does not exist');
                } else {
                    message.error('There was a problem creating your Request');
                }
                console.error('Request creation error', error);
            }
        } finally {
            setLoading(false);
            setCreateLoading(false);
        }
    };

    //list file details
    const listFileDetails = async (
        {
            uploadId,
            caseId,
            customerId,
        }: {
            uploadId?: string;
            caseId?: string;
            customerId?: string;
        }) => {
        setLoading(true);
        let params: ListUploadsRequest = {};
        if (caseId) {
            params = { caseId };
        } else if (customerId) {
            params = { customerId };
        }

        const headers = await getHeaders();
        try {
            const { data } = await axios.get<ListUploadsResponse>(`${apiPrefix}/api/uploads`, { headers, params });

            if (data.length > 0) {
                const currentTime = new Date().getTime();
                // mark any expired uploads in requested, uploading, or scanning status as expired
                const processedData: ListUploadEntry[] = data.map((record) => {
                    if (currentTime > Number(record.expiresAt) && (record.status === 'requested' ||
                        record.status === 'Requested' || record.status === 'Scanning' || record.status === 'Uploading')) {
                        return {
                            ...record,
                            status: 'Expired',
                        };
                    }
                    return record;
                });

                // Set Current Request Status
                const currentRequest = processedData.find(request => request['uploadId'] === uploadId);
                setRequestStatus(currentRequest?.status || '');

                // Flatten the data for the table
                const flattenedData = flattenFileDetails(processedData);
                setFlattenedFileDetails(flattenedData);
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                message.error('There was a problem retrieving file details');
                console.error('list files error', e.message);
            }
        } finally {
            setLoading(false);
            setRefreshLoading(false);
        }
    };

    // Customize Request Status based on File Upload Status
    let requestStatuses: Record<string, JSX.Element> = {
        'Completed':
            <RequestStatusCompleted>
                <LikeOutlined style={{ marginRight: '5px' }} />
                File Upload Complete
            </RequestStatusCompleted>,
        'Failed':
            <RequestStatusFailed>
                <ExclamationCircleOutlined style={{ marginRight: '5px' }} />
                Corrupt File
            </RequestStatusFailed>,
        'Scanning':
            <RequestStatusScanning>
                <MonitorOutlined style={{ marginRight: '5px' }} />
                Scanning File
            </RequestStatusScanning>,
        'Uploading':
            <RequestStatusUploading>
                <CloudUploadOutlined style={{ marginRight: '5px' }} />
                Received | Awaiting Neighbor Upload
            </RequestStatusUploading>,
        'Requested':
            <RequestStatusRequested>
                <SendOutlined style={{ marginRight: '5px' }} />
                Request Sent
            </RequestStatusRequested>,
        'Expired':
            <RequestStatusFailed>
                <ExclamationCircleOutlined style={{ marginRight: '5px' }} />
                Request Expired
            </RequestStatusFailed>,
    };

    // Temporarily allow old statuses to work with new status setup
    requestStatuses['complete'] = requestStatuses['Completed'];
    requestStatuses['pending'] = requestStatuses['Scanning'];
    requestStatuses['open'] = requestStatuses['Uploading'];
    requestStatuses['requested'] = requestStatuses['Requested'];
    requestStatuses['Malware'] = requestStatuses['Failed'];

    // Table Columns
    const tableColumns: ColumnsType<FlattenedListUploadEntry> = [
        {
            title: 'Download',
            dataIndex: 'uploadId',
            width: 175,
            render (value: string, record: FlattenedListUploadEntry, _index: number) {
                const caseId = record.caseId;
                const conversionStatus = record.conversionStatus || 'N/A';
                // customize what button displays based on status
                if (record.status === 'complete' || record.status === 'Completed') {
                    return (
                        <FileWrapper>
                            <DownloadAttachmentButton
                                caseId={caseId}
                                conversionStatus={conversionStatus}
                                uploadId={record.uploadId}
                                fileName={record.fileName || ''}
                                fileId={record.fileId || ''}
                                multiFile={!!record.fileId}
                            />
                        </FileWrapper>
                    );
                }
                if (record.status === 'open' || record.status === 'Uploading') {
                    return (
                        <Button
                            type='default'
                            disabled
                            icon={<CloudUploadOutlined />}
                            style={{
                                backgroundColor: '#fff',
                                color: '#1998d5',
                                border: '1px solid #1998d5',
                                margin: '-5px 0px',
                            }}

                        >
                            Awaiting Upload
                        </Button>
                    );
                }
                if (record.status === 'pending' || record.status === 'Scanning') {
                    return (
                        <Button
                            type='default'
                            disabled
                            icon={<MonitorOutlined />}
                            style={{
                                backgroundColor: '#fff',
                                color: '#1998d5',
                                border: '1px solid #1998d5',
                                margin: '-5px 0px',
                            }}

                        >
                            Scanning File
                        </Button>
                    );
                }
                if (record.status === 'Expired') {
                    return (
                        <Button
                            type='default'
                            disabled
                            icon={<ExclamationCircleOutlined />}
                            style={{
                                backgroundColor: '#fff',
                                color: 'red',
                                border: '1px solid red',
                                margin: '-5px 0px',
                            }}

                        >
                            Expired
                        </Button>
                    );
                }
                if (record.status === 'requested' || record.status === 'Requested') {
                    return (
                        <Button
                            type='default'
                            onClick={() => {
                                createUpload(caseId, value);
                            }}
                            icon={<ReloadOutlined />}
                            style={{
                                color: 'orange',
                                border: '1px solid orange',
                                margin: '5px 0px',
                            }}
                        >
                            Resend Request
                        </Button>
                    );
                }
                if (record.status === 'Malware' || record.status === 'Failed') {
                    return (
                        <Button
                            type='default'
                            disabled
                            icon={<ExclamationCircleOutlined />}
                            style={{
                                backgroundColor: '#fff',
                                color: 'red',
                                border: '1px solid red',
                                margin: '-5px 0px',
                            }}

                        >
                            Corrupt File
                        </Button>
                    );
                }
            },
        },
        {
            title: 'File Name',
            dataIndex: 'fileName',
            render (value: string, record: FlattenedListUploadEntry, _index: number) {
                return (
                    <FileNameText>
                        {record.fileName}
                    </FileNameText>
                );
            },
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            sorter: (a: FlattenedListUploadEntry, b: FlattenedListUploadEntry) => {
                // Convert the strings to numbers for proper comparison
                return Number(a.createdAt) - Number(b.createdAt);
            },
            defaultSortOrder: 'descend',
            render (value: string, record: FlattenedListUploadEntry, _index: number) {
                const createdString = new Date(+value);
                const createdDate = createdString.toString().slice(3, 21);
                return (
                    <TableRowDate>
                        {createdDate}
                    </TableRowDate>
                );
            },
        },
    ];

    //page content
    return (
        <FileUploadBody>
            {/* Button Component */}
            {((!uploadId || requestStatus === 'Completed' || requestStatus === 'complete' ||
                        requestStatus === 'Expired' || requestStatus === 'Failed') &&
                    caseStatus === 'open' && caseId) &&
                <Button
                    type='primary'
                    icon={<SendOutlined />}
                    loading={createLoading}
                    onClick={() => {
                        setCreateLoading(true);
                        createUpload(caseId);
                    }}
                    style={{ margin: '0px' }}
                >
                    File Upload Request
                </Button>
            }
            {/* Request Details Component */}
            {requestStatus &&
                <>
                    <Divider
                        orientation='left'
                        style={{
                            color: '#1890ff',
                            margin: '10px 0px',
                        }}
                    >
                        <FileSearchOutlined style={{ marginRight: '5px' }} />
                        Request Details
                    </Divider>
                    {requestStatuses[requestStatus]}
                </>
            }
            {/* File Details Component*/}
            <Divider
                orientation='left'
                style={{
                    color: '#1890ff',
                    margin: '15px 0px 10px 0px',
                }}
            >
                <FileImageOutlined style={{ marginRight: '5px' }} />
                File Details
            </Divider>
            <Table
                columns={tableColumns}
                dataSource={flattenedFileDetails || undefined}
                loading={loading}
                showHeader={false}
                rowKey={record => `${record.uploadId}-${record.fileId || record.fileName || ''}`}
                size='small'
                style={{
                    backgroundColor: '#fff',
                }}
                pagination={{
                    pageSize: 5,
                    position: ['bottomCenter'],
                }}
            />
            {/* Refresh List Component*/}
            <FileDetailsReminder>
                Not Seeing the File you are looking for?
            </FileDetailsReminder>
            <Button
                type='primary'
                loading={refreshLoading}
                onClick={() => {
                    setRefreshLoading(true);
                    listFileDetails({ uploadId, caseId, customerId });
                }}
                icon={<ReloadOutlined />}
            >
                Refresh
            </Button>
        </FileUploadBody>
    );
}
